body {
    background-color: '#F5F6F8' !important;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.flex {
    display: flex;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.bold-title-font {
    font-size: 20px;
    font-weight: bold;
}

.bolder-title-font {
    font-size: 20px;
    font-weight: 600;
}

.expanded-ellipsis {
    display: flex;
    gap: 10px;
}

.verification-box-holder {
    min-width: 24px;
    max-width: 24px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.full-width {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.profile-image-holder {
    background-color: transparent;
    cursor: pointer;
    border: 0;
}

.popper-button {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    padding: 0;
    height: 24px;
}

.popper-dropdown-box {
    border: 0;
    padding: 0;
    background-color: #00154F;
    color: #fff;
    border-radius: 10px;
}

.profile-header-rectangle-desktop {
    height: 410px;
    width: 100%;
    background: linear-gradient(90deg, rgba(55, 209, 218, 0.1) 0%, rgba(83,194,191,0.55) 100%);
    box-shadow: 0 1px 2px 0 #E3E8EE;
    text-align: -webkit-center;
}

.profile-header-rectangle-mobile {
    height: 610px;
    width: 100%;
    background: linear-gradient(90deg, rgba(55, 209, 218, 0.1) 0%, rgba(83,194,191,0.55) 100%);
}

.profile-card-paper {
    box-sizing: border-box !important;
    border: 1px solid #DADCE0 !important;
    box-shadow: 0 1px 2px 0 #E3E8EE !important;
}

.required:after {
    content:" *";
    color: red;
    font-size: 14px;
}

.onboarding-bg-rectangle {
    height: 100%;
    width: 100%;
    background: linear-gradient(162.3deg, #D2E4E5 0%, #7ECAC8 100%);
}

.general-onboarding-bg {
    width: 100%;
    background: linear-gradient(90deg, rgba(210,228,229,0.1) 0%, rgba(83,194,191,0.55) 100%);
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.general-onboarding-desktop {
    float: left;
    margin-left: 25px;
    margin-bottom: -15%;
    margin-top: 5%;
    width: 55%;
}

.general-onboarding-mobile {
    margin-bottom: -25%;
    width: 75%
}

.hidden {
    display: none;
}

@font-face { 
    font-family: 'Museo Sans Rounded';
    src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-500.eot') format('eot');
    src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-500.ttf') format('ttf');
    src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-500.woff') format('woff');
    src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-500.woff2') format('woff2');
    src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-300.eot') format('eot');
    src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-300.ttf') format('ttf');
    src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-300.woff') format('woff');
    src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-300.woff2') format('woff2');
}

.customScroll::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
}

.customScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #4DD8E5;
    background-color: #4DD8E5;
}

.customScroll {
    /* Internet Explorer 10+ */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}

.customPathwayScroll::-webkit-scrollbar {
    width: 12px;
    background-color: #FFFFFF;
}

.customPathwayScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #7781FF;
    background-color: #7781FF;
}

.customPathwayScroll {
    /* Internet Explorer 10+ */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}

/* Lock mobile devices to portrait */
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
}
/* Lock tablet devices to landscape */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    html {
        transform: rotate(90deg);
        transform-origin: right top;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        right: 0;
    }
  }
