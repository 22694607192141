.tabs {
    color: #979FFF ;
    position: relative;
    background:none ;
    border:none;
    text-transform: capitalize;
    font-size: 28px;
    font-weight: 500;
        
}
.RRT__container .RRT__tabs .RRT__tab--selected {
    color: white;
}
.RRT__container .RRT__tabs .RRT__tab--selected:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 4px;
    background: #73E8F3;
    bottom: 0;
    margin: 0 auto;
    border-radius: 10px;
}
.RRT__tab:focus{
    background: none;
}
.selected {
    background: white;
}
.panel{
    border:none
}  

@media (max-width: 1023px) {
    .tabs {
        font-size: 18px;
    }
    .RRT__container .RRT__tabs .RRT__tab--selected:after{
        display: none;
    }
}
